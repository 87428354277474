@font-face {
  font-family: 'Neue-Haas';
  src: url('../fonts/Neue-Haas.ttf') format('truetype');
}

@font-face {
  font-family: 'Zumee-400';
  src: url('../fonts/Zuume-400.ttf') format('truetype');
}
@font-face {
  font-family: 'Zumee-600';
  src: url('../fonts/Zuume-600.ttf') format('truetype');
}
